







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'FlagshipGoalsStepIndicator' })
export default class FlagshipGoalsStepIndicator extends Vue {
  @Prop(Boolean)
  is_current!: boolean;
}
